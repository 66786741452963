<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Remitos de Compras</h4>
            <div class="small text-muted">Cargar y administra todos los remitos de recpción de mercaderia</div>
          </b-col>
          
          <b-col sm="5">
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card id="list-purchase-remits" v-if="table.mostrarFiltros">
        <b-row >
          <b-col sm="10">            
            <b-row>
              <b-col md="2" class="pr-0">
                <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
              </b-col>
              <b-col md="2" class="pl-0">
                <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
              </b-col>  
              <b-col sm="4">
                <v-select :options="arr.filters.suppliers" v-model="filters.suppliers" placeholder="Proveedores" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>  
              <b-col sm="2">
                <b-button variant="outline-dark" @click="filterPurchaseRemits()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="2">
            <b-link>
              <export-excel
                class = 'pull-right'
                :data = "arr.export"
                worksheet = "Listado de Remitos de Compras"
                name = "list-purchase-remits.xls">
                Exportar Datos
              </export-excel>            
            </b-link>
          </b-col>
        </b-row>              
      </b-card>    

      <b-row>
        <b-col lg="8" class="pr-0" id="view_list_purchase_remits">
          <b-card>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" 
                                  placeholder="Ingrese su busqueda para filtrar la grilla" 
                                  v-model="table.filter"
                                  size="sm">
                    </b-form-input>              
                  </b-input-group>
                </b-form-group>            
              </b-col>
              <b-col>
                <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Remitos">
                  <i class="fa fa-plus"></i> Agregar
                </b-button>                
              </b-col>              
              <b-col sm="12">
                <b-table class="mb-0 table-purchase-remits-custom"
                        ref="table"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected"  
                        :busy="table.isBusy"                      
                        v-if="table.items.length || table.isBusy">                       
                    
                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template> 

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>
                    
                    <template v-slot:cell(business)="data">
                      {{data.item.points_sales.business.reference}}
                    </template>                    
                    
                    <template v-slot:cell(voucher)="data">
                      <div v-if="data.item.type_voucher && data.item.point_sale">
                        <b-badge variant="dark" v-if="data.item.points_sales">
                          {{data.item.points_sales.point_sale}} - {{data.item.points_sales.name}}
                        </b-badge> <br>
                        {{data.item.type_voucher.name}}
                        {{data.item.point_sale.toString().padStart(4,'0') + '-' + data.item.number.toString().padStart(8,'0')}}
                      </div>
                    </template>
                    
                    <template v-slot:cell(date)="data">
                      {{moment(data.item.date).format('DD/MM/YYYY')}}
                    </template>
                    
                    <template v-slot:cell(suppliers_id)="data">  
                      <div v-if="data.item.supplier">            
                        <b-avatar :src="data.item.supplier.image"
                                  v-if="data.item.supplier.image">
                        </b-avatar>
                        <b-avatar v-else 
                                  icon="building"
                                  variant="dark">
                        </b-avatar>                                                     
                        &nbsp;<b>{{data.item.supplier.name}}</b>   
                        
                        <b-icon icon="circle-fill" v-if="!data.item.supplier.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Proveedor INACTIVO" />
                      </div>
                    </template>

                    <template v-slot:cell(observations)="data">
                      <span v-if="data.item.file">
                        <b-link target="blank" :href="data.item.file" v-b-tooltip.hover title="Ver documento adjunto" class="text-center">
                          <b-icon icon="paperclip"></b-icon>
                        </b-link>
                      </span>                                  
                      <span v-if="data.item.observations" v-b-tooltip.hover :title="data.item.observations" class="mr-1 text-center">
                        <b-icon icon="chat-square-text"></b-icon>
                      </span>                                                           
                    </template>
                    
                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="Acción" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right" 
                                  @show="setConfigTablePurchaseRemitsShow"
                                  @hide="setConfigTablePurchaseRemitsHide">                        
                        <b-dropdown-item @click="sendPrinter(data.item)">
                          <b-icon icon="printer"></b-icon> Imprimir
                        </b-dropdown-item>                                        
                        <b-dropdown-item @click="openSetPrice(data.item)">                          
                          <i class="fa fa-dollar crud-purchase-remits-action-icon"></i> Precios               
                        </b-dropdown-item>                                  
                        <b-dropdown-header>Acciones</b-dropdown-header>
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>                          
                        <b-dropdown-item @click="remove(data.item)" v-if="access.elements.deletePurchaseRemits">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="4" id="contain_details_items">
          
          <div id="details_items">
            <b-card>            
              <b-row>              
                <b-col md="12">
                  
                  <!-- COMPROBANTE SELECCIONADO -->
                  <b-row class="mb-3">
                    <b-col md="8">
                      <div class="crud-purchase-remits-title" v-if="itemSelected">
                        <div class="crud-purchase-remits-title">
                          <span class="crud-purchase-remits-code" v-if="itemSelected.type_voucher && itemSelected.point_sale">
                            {{itemSelected.type_voucher.name}} {{itemSelected.point_sale.toString().padStart(4,'0') + '-' + itemSelected.number.toString().padStart(8,'0')}}
                          </span><br>
                          <span v-if="itemSelected.supplier">
                            {{this.itemSelected.supplier.name}}                          
                          </span>                          
                        </div>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <b-row>
                        <b-col md="12">
                          <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="hideSub()" v-b-tooltip.hover title="Ocultar Detalle">
                            <i class="fa fa-window-close"></i>
                          </b-button>
                        </b-col>                      
                      </b-row>                   
                    </b-col>
                  </b-row>

                  <!-- DETALLE -->  
                  <b-row>
                    <b-col>
                      <b-link v-if="itemSelected"
                              @click="openDetail()"
                              class="pull-right">
                        Ver detalle completo
                      </b-link>                                                           
                    </b-col>
                  </b-row>      
                                    
                  <b-row v-if="tableSub.items.length">
                    <b-col md="12">
                      <b-table  class="mb-0 table-purchase-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSub.items"
                                :fields="tableSub.fields"                            
                                v-if="tableSub.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template> 
                                     
                        <template v-slot:cell(quantity)="row"> 
                          {{row.item.type_balance}} {{row.item.quantity}}
                          <span v-if="row.item.unit_measurement">{{row.item.unit_measurement.reference}}</span>
                        </template>

                        <template v-slot:cell(product)="row">
                          <div v-html="getDetailName(row.item)"></div>
                        </template>
                      </b-table>                      
                      <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                    </b-col>                    
                  </b-row>
                  
                </b-col>
              </b-row>
            </b-card>           
          </div>
        </b-col>
      </b-row> 

      <b-row>
        <b-col lg="8" class="pr-0" id="view_footer_purchase_remits">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="filterPurchaseRemits()" />
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>        

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close      
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <form-wizard  title=""
                      subtitle=""
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Guardar"
                      color="#2f353a"
                      shape="tab"
                      @on-complete="save"
                      class="crud-sales-wizard">

          <tab-content title="General" icon="fa fa-edit" :before-change="purchaseRemitsValidDetailGeneral">          
            <b-row>      
              <b-col md="4">
                <b-form-group label="Fecha">
                  <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>           
            </b-row>    
            <b-row> 
              <b-col md="5">
                <FindObject render="search"
                            type="suppliers" 
                            @select-object="loadSuppliers($event)" 
                            :valueID="crud.form.suppliers_id"
                            :where="conditionSuppliersActive"
                            :disabled="crud.form.id>0" />                 
              </b-col>                                                                                             
              <b-col md="3"> 
                <FindObject tag="Punto de Venta (Imputación)"
                            render="search"
                            type="erpPointsSales" 
                            display="full_identification"
                            @select-object="loadPointsSales($event)" 
                            :valueID="crud.form.points_sales_id"
                            :key="'finder_point_sale_'+componentKey"
                            :disabled="crud.form.id>0"  />                 
              </b-col>                                         
            </b-row>
            <b-row> 
              <b-col md="3">
                <b-form-group label="Comprobante">    
                  <b-form-input value="REMITO" 
                                type="text" 
                                size="sm"
                                :readonly="true">
                  </b-form-input>            
                </b-form-group>                            
              </b-col>                
              <b-col md="2">
                <b-form-group label="Punto de Venta">    
                  <b-form-input v-model="crud.form.point_sale" 
                                type="number" 
                                size="sm" 
                                min="0" 
                                max="9999" 
                                step="1">
                  </b-form-input>            
                </b-form-group>                            
              </b-col>  
              <b-col md="3">
                <b-form-group label="Número">    
                  <b-form-input v-model="crud.form.number" 
                                type="number" 
                                size="sm" 
                                min="0" 
                                max="99999999" 
                                step="1">
                  </b-form-input>                              
                </b-form-group>                            
              </b-col>                         
            </b-row>             
          </tab-content>


          <tab-content title="Detalle" icon="fa fa-list" :before-change="purchaseRemitsValidDetailItems">          
            <b-row>
              <b-col lg="12" md="12" sm="12">                                  
                <Detail @load-detail-products="setDetailItems($event, 'products')" 
                        @load-detail-products-compound="setDetailItems($event, 'productsCompound')" 
                        :operation="crud.form"
                        :detailProducts="crud.form.detailProducts"
                        :detailProductsCompound="crud.form.detailProductsCompound"
                        :key="'detail_' + componentKey" 
                        :forceUpdate="componentKey" />                                             
              </b-col>        
            </b-row>
          </tab-content>

          <tab-content title="Observaciones" icon="fa fa-flag-checkered" :key="'tab_f_'+componentKey">          
            <b-row>
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea                
                    v-model="crud.form.observations"
                    placeholder="Ingresar las observaciones..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>         
            </b-row>
          </tab-content>

        </form-wizard>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="cancel()">Cancelar</b-button>              
        </div>                
      </b-modal>

      <!-- CRUD DETAILS -->
      <b-modal v-model="modal.detail.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.detail.title}}
        </div>

        <b-row>
          <b-col sm="12">                     
            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
              <b-tab title="General">
                <table class="table table-hover table-sm" v-if="itemSelected">
                  <tbody>
                    <tr>
                      <td class="crud-purchase-remits-title-table-custom align-middle">ID</td>
                      <td class="crud-purchase-remits-value-table-custom align-middle">
                        <b>#{{this.itemSelected.id}}</b>
                      </td>
                    </tr>       
                    <tr>
                      <td class="crud-purchase-remits-title-table-custom align-middle">Empresa</td>
                      <td class="crud-purchase-remits-value-table-custom align-middle">
                        {{this.itemSelected.points_sales.business.reference}}
                      </td>
                    </tr>                    
                    <tr v-if="itemSelected.points_sales">
                      <td class="crud-purchase-remits-title-table-custom align-middle">Punto de Venta Imputación</td>
                      <td class="crud-purchase-remits-value-table-custom align-middle">
                        {{this.itemSelected.points_sales.point_sale}} - {{this.itemSelected.points_sales.name}}                          
                      </td>
                    </tr>                                      
                    <tr v-if="itemSelected.type_voucher && itemSelected.point_sale">
                      <td class="crud-purchase-remits-title-table-custom align-middle">Comprobante</td>                      
                      <td class="crud-purchase-remits-value-table-custom align-middle">
                        <b>{{this.itemSelected.type_voucher.name + ' '  + this.itemSelected.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.number.toString().padStart(8,'0')}}</b>
                      </td>
                    </tr>                                                           
                    <tr v-if="itemSelected.staff">
                      <td class="crud-purchase-remits-title-table-custom align-middle">Emisor</td>                      
                      <td class="crud-purchase-remits-value-table-custom align-middle">
                        {{itemSelected.staff.name}}  
                      </td>
                    </tr>                                                             
                    <tr>
                      <td class="crud-purchase-remits-title-table-custom align-middle">Fecha</td>
                      <td class="crud-purchase-remits-value-table-custom align-middle">
                        {{moment(this.itemSelected.date).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                      
                    <tr v-if="itemSelected.supplier">
                      <td class="crud-purchase-remits-title-table-custom align-middle">Proveedor</td>
                      <td class="crud-purchase-remits-value-table-custom align-middle">
                        {{this.itemSelected.supplier.name}}                        
                      </td>
                    </tr>                                                  
                    <tr v-if="itemSelected.observations">
                      <td class="crud-purchase-remits-title-table-custom align-middle">Observaciones</td>
                      <td class="crud-purchase-remits-value-table-custom align-middle">                        
                        {{this.itemSelected.observations}}
                      </td>
                    </tr>                                                        
                  </tbody>
                </table>
              </b-tab>    

              <b-tab title="Items">                
                <b-table class="mb-0 table-full-detail-purchase-remits"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetail.items"
                        :fields="tableDetail.fields"
                        v-if="tableDetail.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(code)="data">                           
                    <div v-html="getDetailCode(data.item)"></div>
                  </template>

                  <template v-slot:cell(product)="data">                           
                    <div v-html="getDetailName(data.item)"></div>
                  </template>

                  <template v-slot:cell(deposit)="data">   
                    {{data.item.location.zone.deposit.reference}} / {{data.item.location.zone.reference}} / {{data.item.location.reference}}
                  </template>

                  <template v-slot:cell(quantity)="data">   
                    {{data.item.quantity}}
                    <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
                  </template>

                  <template v-slot:cell(price_cost)="data">   
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(data.item.price_cost)}}                                    
                  </template>

                  <template v-slot:cell(price_total)="data">   
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(parseFloat(data.item.quantity) * parseFloat(data.item.price_cost))}}                                    
                  </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>              
            </b-tabs>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.detail.active=false">Cancelar</b-button>                   
        </div>
      </b-modal>

      <!-- CRUD PRICES -->
      <b-modal v-model="modal.price.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.price.title}}
        </div>

        <b-row>         
          <b-col md="12">
            <div class="crud-purchase-remits-price-title" v-if="itemSelected">
              <div class="crud-purchase-remits-price-title">
                <span v-if="itemSelected.supplier" class="crud-purchase-remits-price-code">
                  {{this.itemSelected.supplier.name}}   
                </span><br>
                <span v-if="itemSelected.type_voucher && itemSelected.point_sale">
                  {{this.itemSelected.type_voucher.name + ' '  + this.itemSelected.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.number.toString().padStart(8,'0')}}
                </span>
              </div>              
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <hr>
          </b-col>
        </b-row>
        <b-row>         
          <b-col md="12">              
            <div v-if="itemSelectedPrice">
              <b-row class="purchase-remits-list-price-cost">
                <b-col>
                  <b-row v-for="item in itemSelectedPrice" :key="item.id" class="mb-1"> 
                    <b-col md="5">
                      <b-row>
                        <b-col md="3" class="pr-0">                  
                          <b-form-input v-model="item.products_code" 
                                        type="text" 
                                        size="sm"
                                        :readonly="true">
                          </b-form-input>                              
                        </b-col>                    
                        <b-col md="9" class="pr-0">                  
                          <b-form-input v-model="item.products_name" 
                                        type="text" 
                                        size="sm"
                                        :readonly="true">
                          </b-form-input>                              
                        </b-col> 
                      </b-row>
                    </b-col>
                    <b-col md="7">
                      <b-row>
                        <b-col md="3" class="pr-0" v-if="item.unit_measurement">                                    
                          <b-input-group size="sm" :append="item.unit_measurement.reference" :title="item.unit_measurement.name">
                            <b-form-input v-model="item.quantity" 
                                          name="quantity"
                                          type="number" 
                                          size="sm"
                                          :readonly="true">
                            </b-form-input>                              
                          </b-input-group>
                        </b-col>
                        <b-col md="3">
                          <b-input-group prepend="$" size="sm">
                            <b-form-input v-model="item.price_cost" 
                                          name="price" 
                                          min="0"
                                          step="0.01"
                                          type="number">
                            </b-form-input>                    
                          </b-input-group>          
                        </b-col>   
                        <b-col md="3">
                          <b-input-group prepend="$" size="sm">
                            <b-form-input :value="(parseFloat(item.price_cost) * parseFloat(item.quantity)).toFixed(2)" 
                                          name="price" 
                                          min="0"
                                          step="0.01"
                                          :readonly="true"
                                          type="number">
                            </b-form-input>                    
                          </b-input-group>          
                        </b-col>                       
                        <b-col md="2" class="purchase-remits-price-cost-divider">
                          <span :id="'popover-purchase-remits-price-cost-' + item.id" v-if="item.selectProduct.product_purchase">
                            <a href="javascript:void();">
                              {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(item.selectProduct.product_purchase.price_list)}}
                            </a>
                          </span>
                          <b-popover :target="'popover-purchase-remits-price-cost-' + item.id" variant="info" triggers="hover" v-if="item.selectProduct.product_purchase">
                            <template #title>Costo del Producto</template>
                            <table class="table table-hover table-sm" v-if="itemSelected">
                              <tbody>
                                <tr>
                                  <td class="crud-purchase-remits-title-table-price align-middle">
                                    DESCUENTO
                                  </td>
                                  <td class="crud-purchase-remits-value-table-price align-middle">
                                    <span v-if="getDiscount(item.selectProduct.product_purchase,1)"> -{{getDiscount(item.selectProduct.product_purchase,1)}} </span>
                                    <span v-if="getDiscount(item.selectProduct.product_purchase,2)"> -{{getDiscount(item.selectProduct.product_purchase,2)}} </span>
                                    <span v-if="getDiscount(item.selectProduct.product_purchase,3)"> -{{getDiscount(item.selectProduct.product_purchase,3)}} </span>
                                    
                                    <span v-if="getDiscount(item.selectProduct.product_purchase,1) || getDiscount(item.selectProduct.product_purchase,2) || getDiscount(item.selectProduct.product_purchase,3)">
                                      %
                                    </span>                                
                                  </td>
                                </tr>
                                <tr>
                                  <td class="crud-purchase-remits-title-table-price align-middle">
                                    ADICCIONAL
                                  </td>
                                  <td class="crud-purchase-remits-value-table-price align-middle">
                                    <span v-if="getAdditional(item.selectProduct.product_purchase,1)"> +{{getAdditional(item.selectProduct.product_purchase,1)}} </span>
                                    <span v-if="getAdditional(item.selectProduct.product_purchase,2)"> +{{getAdditional(item.selectProduct.product_purchase,2)}} </span>                    
                                    
                                    <span v-if="getAdditional(item.selectProduct.product_purchase,1) || getAdditional(item.selectProduct.product_purchase,2)">
                                      %
                                    </span>                                
                                  </td>
                                </tr>                            
                                <tr v-if="item.selectProduct.product_purchase.additional">
                                  <td class="crud-purchase-remits-title-table-price align-middle">
                                    EXTRA
                                  </td>
                                  <td class="crud-purchase-remits-value-table-price align-middle">
                                    + {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(item.selectProduct.product_purchase.additional)}}                                             
                                  </td>
                                </tr>   
                                <tr>
                                  <td class="crud-purchase-remits-title-table-price align-middle">
                                    COSTO
                                  </td>
                                  <td class="crud-purchase-remits-value-table-price align-middle">
                                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(item.selectProduct.product_purchase.price_cost)}}
                                  </td>
                                </tr>                               
                              </tbody>
                            </table>                        
                          </b-popover>   

                          <span v-if="!item.selectProduct.product_purchase">
                            <i class="purchase-remits-price-cost-not-related">**No vinculado**</i>
                          </span>
                        </b-col> 
                        <b-col md="1" title="Actualizar el precio de lista" class="purchase-remits-price-cost-update">
                          <b-form-checkbox v-model="item.update_price" 
                                            size="sm" 
                                            v-if="item.selectProduct.product_purchase && (item.price_cost > item.selectProduct.product_purchase.price_list)" >
                          </b-form-checkbox>                         
                        </b-col>                    
                      </b-row>
                    </b-col>                                
                  </b-row>    
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <hr>
                </b-col>
              </b-row>       
              <b-row>              
                <b-col md="5"></b-col>
                <b-col md="7">
                  <b-row>
                    <b-col md="3" class="pr-0"></b-col>
                    <b-col md="3"></b-col>
                    <b-col md="3">
                      <b-input-group prepend="$" size="sm">
                        <b-form-input :value="getSummary" 
                                      name="summary" 
                                      :readonly="true"
                                      step="0.01"
                                      type="number">
                        </b-form-input>                    
                      </b-input-group>                        
                    </b-col>   
                    <b-col md="2"></b-col> 
                    <b-col md="1"></b-col>                    
                  </b-row>                  
                </b-col>
              </b-row>                        
            </div>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>            

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.price.active=false">Cancelar</b-button>                   
          <b-button variant="dark" @click="saveSetPrice()">Guardar</b-button>                   
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'  
  import moment from 'moment'
  import Printer from '@/components/inc/printer/printer'  
  import Detail from '@/components/inc/cpbtDetailDeposit/detail.vue'
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'

  export default { 
    components: {
      FindObject,
      Detail,
      FormWizard,
      TabContent,      
      Printer,      
    },       
    data: () => {
      return {
        access: {
          module_id: Modules.REMITOS_COMPRAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'purchaseRemits',
          elements: {
            deletePurchaseRemits: true
          }
        },
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,          
        },   
        tableSub : {
          items: [],
          fields: []
        },    
        tableDetail : {
          items: [],
          fields: []
        },             
        crud: {
          form: {
            id: 0,
            date: '',
            types_vouchers_id: 0,
            types_vouchers: null,
            letter: '',
            point_sale: 0,
            number: 0,
            observations: '',            
            staff_id: 0,              
            suppliers_id: 0,
            suppliers: null,      
            points_sales_id: 0,
            points_sales: null, 
            selectTypeVoucher: 'remito',
            reference_voucher_id: 0,                                      
            detailProducts: [],
            detailProductsCompound: [],
            type: {
              type_balance: '+',
            }
          },
          print: {
            reference: '',
            id: 0,
            key: 0,
          }                        
        },          
        modal: {
          form: {
            active: false,
            title: '',
          },
          detail: {
            active: false,
            title: ''
          },
          price: {
            active: false,
            title: ''
          }                    
        },                     
        arr: {
          purchaseRemits: [],   
          export: [],          
          filters : {
            suppliers: [],
          }
        },           
        filters: {
          date_start: '',
          date_end: '',
          suppliers: null,
        },  
        itemSelected: null,        
        itemSelectedPrice: null,       
        componentKey: 0,
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      this.access.elements.deletePurchaseRemits = Helper.hasAccessElement(this.access, 'delete_purchase_remits') 
      /* Fin configuracion */
    },
    computed: {
      // CONDITIONS SELECT
      conditionSuppliersActive(){
        return [{field: 'active', condition: true}];
      },

      getCurrency(){
        return "ARS"
      },

      getSummary() {        
        var total = 0
        if(this.itemSelectedPrice) {          
          this.itemSelectedPrice.forEach(element => {            
            total = total + (parseFloat(element.price_cost) * parseFloat(element.quantity))
          });
        }
        return total.toFixed(2)
      }      
    },
    mounted() {
      this.loadFieldTable()
      this.filterLoad()   
      this.filterPurchaseRemits()              
      this.loadStyleConfig()            
    }, 
    watch: {        
      'crud.form.suppliers_id': function (newQuestion, oldQuestion) {                         
        this.searchVoucherBySupplier()
      },
      'crud.form.points_sales_id': function (newQuestion, oldQuestion) {                        
        this.searchVoucherBySupplier()
      },
    },        
    methods: {
      // CONFIGURACION
      loadStyleConfig () {        
        var view_list_purchase = document.getElementById('view_list_purchase_remits')
        var view_footer_purchase = document.getElementById('view_footer_purchase_remits')

        var padre = document.getElementById('contain_details_items')
        var hijo = document.getElementById('details_items')  
              
        hijo.style.width = padre.clientWidth - 45 + 'px'

        if(window.innerWidth < 991) {
          hijo.style.width = '100%'
          hijo.style.position = "relative"
          hijo.style.right = 'auto'

          view_list_purchase.classList.remove('pr-0')
          view_footer_purchase.classList.remove('pr-0')
        } else {          
          view_list_purchase.classList.add('pr-0')
          view_footer_purchase.classList.add('pr-0')
        }        
      },       
      loadFieldTable () {
        // TABLE COMPROBANTES
        this.table.fields.push({key: 'business', label: 'Empresa', class:"align-middle text-left", width:"60px"})
        this.table.fields.push({key: 'voucher', label: 'Comprobante', class:"align-middle text-left", width:"150px"})
        this.table.fields.push({key: 'date', label: 'Fecha', class:"align-middle text-center", width:"90px"})
        this.table.fields.push({key: 'suppliers_id', label: 'Proveedor', class:"align-middle", width:"150px"})
        this.table.fields.push({key: 'observations', label: '', class:"align-middle text-center", width:"25px"})
        this.table.fields.push({key: 'f_action', label:'', class:"align-middle", width:"60px"})

        // TABLE DETALLE
        this.tableSub.fields.push({key: 'quantity', label: 'Cantidad', class:"align-middle", width:"50px"})                
        this.tableSub.fields.push({key: 'product', label: 'Item', class:"align-middle", width:"150px"})  
        
        // TABLE DETALLE COMPLETO        
        this.tableDetail.fields.push({key: 'code', label: 'Código', class:"align-middle", width:"50px"})                
        this.tableDetail.fields.push({key: 'product', label: 'Item', class:"align-middle text-truncate", width:"200px"})                                         
        this.tableDetail.fields.push({key: 'deposit', label: 'Deposito/Zona/Ubicación', class:"align-middle text-left", width:"150px"})          
        this.tableDetail.fields.push({key: 'quantity', label: 'Cantidad', class:"align-middle text-right", width:"60px"})            
        this.tableDetail.fields.push({key: 'price_cost', label: 'Precio', class:"align-middle text-right", width:"60px"})     
        this.tableDetail.fields.push({key: 'price_total', label: 'Total', class:"align-middle text-right", width:"100px"})     
      }, 
      onRowSelected(item) {         
        this.arr.purchaseRemits.forEach((element, index) => {
          this.table.items[index]._showDetails = false
          if(item.length){
            if(element.id == item[0].id) {  
              this.openSub(element)                             
            }
          }
        })
      },       
      setConfigTablePurchaseRemitsShow() {
        var arrTable = document.getElementsByClassName('table-purchase-remits-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {          
          arrTable[i].style.minHeight = '150px'
        }        
      },
      setConfigTablePurchaseRemitsHide() {
        var arrTable = document.getElementsByClassName('table-purchase-remits-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },  
      forceRender() {
        this.componentKey += 1;  
      },

      // EXPORT
      prepareExport(data) {             
        this.arr.export = []
        data.forEach(element => {
          var businessID = 0
          var businessName = ""          
          var suppliersID = 0
          var suppliersCode = ""
          var suppliersName = ""
          var typeVoucherID = 0
          var typeVoucherName = ""
          var pointSalesID = 0
          var pointSalesName = ""

          if(element.points_sales && element.points_sales.business) {
            businessID = element.points_sales.business.id            
            businessName = element.points_sales.business.reference
          }
          if(element.supplier) {
            suppliersID = element.supplier.id
            suppliersCode = element.supplier.code
            suppliersName = element.supplier.name            
          }       

          if(element.type_voucher) {
            typeVoucherID = element.type_voucher.id
            typeVoucherName = element.type_voucher.name                        
          }

          if(element.points_sales) {
            pointSalesID = element.points_sales.id
            pointSalesName = element.points_sales.name                        
          }          

          this.arr.export.push({
            id: element.id,
            businessID: businessID,
            businessName: businessName,            
            date: element.date,          
            typeVoucherID: typeVoucherID,
            typeVoucherName: typeVoucherName,
            letter: element.letter,
            point_sale: element.point_sale,
            number: element.number,            
            suppliersID: suppliersID,
            suppliersCode: suppliersCode,
            suppliersName: suppliersName,            
            observations: element.observations,
            pointSalesID: pointSalesID,
            pointSalesName: pointSalesName,      
            productID: 0,
            productCode: "",
            productName: "",            
            productCompoundID: 0,
            productCompoundName: "",                                                      
            quantity: 0,
            unitMeasurementID: 0,
            unitMeasurementName: "",                      
          })  

          element.details.forEach(element1 => {
            var productID = 0
            var productCode = ""
            var productName = ""
            var productCompoundID = 0
            var productCompoundName = ""     
            var unitMeasurementID = 0
            var unitMeasurementName = ""               

            if(element1.products_id) {
              productID = element1.product.id
              productCode = element1.product.code
              productName = element1.product.name            
            }

            if(element1.products_colors_id) {
              productID = element1.products_color.id
              productCode = element1.products_color.code
              productName = element1.products_color.name + " (" + element1.products_color.color.name + ")"
            }            

            if(element1.products_waist_id) {
              productID = element1.products_waist.id
              productCode = element1.products_waist.code
              productName = element1.products_waist.name + " (" + element1.products_waist.waist.name + ")"
            }            

            if(element1.products_color_waist_id) {
              productID = element1.products_color_waist.id
              productCode = element1.products_color_waist.code
              productName = element1.products_color_waist.name + " (" + element1.products_color_waist.color.name + " - " + element1.products_color_waist.waist.name + ")"
            }       
          
            if(element1.products_compound_id) {
              productCompoundID = element1.products_compound.id
              productCompoundName = element1.products_compound.name            
            }    

            if(element1.unit_measurement_id) {
              unitMeasurementID = element1.unit_measurement.id
              unitMeasurementName = element1.unit_measurement.name            
            }            

            this.arr.export.push({
              productID: productID,
              productCode: productCode,
              productName: productName,
              productCompoundID: productCompoundID,
              productCompoundName: productCompoundName,                                                        
              quantity: element1.quantity.replace('.',','),
              unitMeasurementID: unitMeasurementID,
              unitMeasurementName: unitMeasurementName,              
            })
          })          
        });
      },

      // ABM MAIN
      add() {										
        this.crud.form.id = 0           
        this.crud.form.date = moment().format('YYYY-MM-DD')        
        this.crud.form.observations = ''
        this.crud.form.staff_id = Helper.getStaffId()        
        this.crud.form.suppliers_id = 0
        this.crud.form.suppliers = null                                   
        this.crud.form.types_vouchers_id = 0
        this.crud.form.types_vouchers = null                     
        this.crud.form.letter = ''
        this.crud.form.point_sale = 0
        this.crud.form.number = 0         
        this.crud.form.points_sales_id = 0
        this.crud.form.points_sales = null
        
        this.crud.form.detailProducts = []
        this.crud.form.detailProductsCompound = []
        
        this.modal.form.active = true
        this.modal.form.title = 'Remito de Compra'
      },  
      edit(item) {										
        this.crud.form.id = item.id
        this.crud.form.date = moment(item.date).format('YYYY-MM-DD')        
        this.crud.form.observations = item.observations
        this.crud.form.staff_id = item.staff_id
        this.crud.form.suppliers_id = item.suppliers_id
        this.crud.form.suppliers = item.supplier                   
        this.crud.form.types_vouchers_id = item.types_vouchers_id
        this.crud.form.types_vouchers = item.type_voucher
        this.crud.form.letter = item.letter
        this.crud.form.point_sale = item.point_sale
        this.crud.form.number = item.number
        this.crud.form.points_sales_id = item.points_sales_id
        this.crud.form.points_sales = item.points_sales
        
        var result = serviceAPI.mostrarRemitos(item.id);

        result.then((response) => {  
          var data = response.data

          if(data) {
            if(data.details.products) {
              this.crud.form.detailProducts = data.details.products
            }            

            if(data.details.products_compound) {
              this.crud.form.detailProductsCompound = data.details.products_compound
            }

            this.forceRender()
          } 
        })    
                
        this.modal.form.active = true
        this.modal.form.title = 'Editar Remito de Compra'
      },       
      remove(item) {
        this.crud.form.id = item.id
        
        var voucher = "ID #" + item.id
        if(item.type_voucher && item.point_sale) {
          voucher = item.type_voucher.name + ' ' + item.point_sale.toString().padStart(4,'0') + '-' + item.number.toString().padStart(8,'0')
        }        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el remito de compra ' + voucher + '?', {
          title: 'Borrar Remito',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarRemitos(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterPurchaseRemits()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },          
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la generación del remito de compra?', {
          title: 'Generar Remito',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();
            
            var result = null
            if(this.crud.form.id) {
              result = serviceAPI.editarRemitos(this.crud.form);
            } else {
              result = serviceAPI.agregarRemitos(this.crud.form);
            }            

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.filterPurchaseRemits()
              this.$awn.success("Remito generado con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })              
      },  
      cancel() {
        this.$bvModal.msgBoxConfirm('¿Desea cancelar el remito de compra?', {
          title: 'Cancelar el Remito',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            this.modal.form.active = false
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },

      // ABM VALIDACIONES
      purchaseRemitsValidDetailGeneral() {               
        if( this.crud.form.suppliers_id && 
            this.crud.form.points_sales_id && 
            this.crud.form.types_vouchers_id && 
            this.crud.form.letter && 
            this.crud.form.point_sale && 
            this.crud.form.number) {          
          return true;
        } else {
          if(!this.crud.form.suppliers_id) {
            this.$awn.alert("No se cargó el proveedor");
            return false;
          }            

          if(!this.crud.form.points_sales_id) {
            this.$awn.alert("No se cargó el punto de venta de imputación");
            return false;
          }            

          if( !this.crud.form.types_vouchers_id ||
              !this.crud.form.letter || 
              !this.crud.form.point_sale ||
              !this.crud.form.number ) {                
            this.$awn.alert("Faltan datos del comprobante");
            return false;         
          }                       
        }        
      },
      purchaseRemitsValidDetailItems() {
          if( this.crud.form.detailProducts.length || 
              this.crud.form.detailProductsCompound.length) { 
            return true;
          } else {
            this.$awn.alert("No se cargó ningún item");
            return false;
          }
      },
      setDetailItems(event, type) {         
        if(type=="products") {
          this.crud.form.detailProducts = event                
        } 
        if(type=="productsCompound") {          
          this.crud.form.detailProductsCompound = event                
        }              
      },   
      
      // ABM FLUJO
      loadSuppliers (object) {        
        if(object){
          if(this.crud.form.suppliers_id != object.id) {
            this.forceRender()
          }

          this.crud.form.suppliers = object
          this.crud.form.suppliers_id = object.id   
                    
          if(object.data_fiscal) {
            if(object.data_fiscal.points_sales_id) {
              if(!this.crud.form.points_sales_id) {
                this.crud.form.points_sales = object.data_fiscal.points_sales
                this.crud.form.points_sales_id = object.data_fiscal.points_sales_id
              }
            }          
          }          
        } else {
          this.crud.form.suppliers = null
          this.crud.form.suppliers_id = 0        
          
          this.crud.form.points_sales = null
          this.crud.form.points_sales_id = 0

          this.cleanDataVoucher()          
        }
      },   
      loadPointsSales (object) {                
        if(object){
          this.crud.form.points_sales = object
          this.crud.form.points_sales_id = object.id             
        } else {
          this.crud.form.points_sales = null
          this.crud.form.points_sales_id = 0
        }
      },                
      cleanDataVoucher() {
        this.arr.typeVouchers = null
        this.arr.select.typeVouchers = []

        this.crud.form.letter = ''
        this.crud.form.types_vouchers_id = 0
        this.crud.form.types_vouchers = null 
      },      

      // SELECT VOUCHER 
      searchVoucherBySupplier() {
        if(!this.crud.form.id) {
          if(this.crud.form.suppliers_id && this.crud.form.points_sales_id ) {
            var result = serviceAPI.obtenerVoucherBySupplier({
              'suppliers_id': this.crud.form.suppliers_id,
              'reference': this.crud.form.selectTypeVoucher,
              'point_sale_id': this.crud.form.points_sales_id,
            });
            result.then((response) => {    
              this.crud.form.types_vouchers_id = response.data[0].id							
              this.searchVoucher()                          
            })
            .catch(error => {
              this.$awn.alert(Error.showError(error));
            })   						           
          }
        }
      },			
      searchVoucher() {
        if(!this.crud.form.id) {
          if(this.crud.form.suppliers_id && this.crud.form.points_sales_id ) {
            var result = serviceAPI.obtenerVoucherById({
              'points_sales_id': this.crud.form.points_sales_id,
              'types_vouchers_id' : this.crud.form.types_vouchers_id,
            });
            result.then((response) => {                   
              this.crud.form.types_vouchers = response.data.type_voucher
              this.crud.form.letter = response.data.type_voucher.letter                            
            })
          }
        }
      },        

      // FILTER
      filterLoad() {
        this.filterLoadSuppliers()
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
      },
      filterLoadSuppliers() {
        var result = serviceAPI.obtenerProveedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.suppliers = []
          data.forEach(element => {            
            this.arr.filters.suppliers.push({ code: element.id, label: element.name })            
          });          
        })   
      },       
      filterPurchaseRemits(forceOpenSub=false) {
        this.table.isBusy = true
        var result = serviceAPI.filtrarRemitos(this.filters, this.table.currentPage)        
        result.then((response) => {      
          
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data
          this.arr.purchaseRemits = data.data
          this.prepareExport(data.data)
          
          this.table.items.forEach(element => {            
            if(forceOpenSub) {
              if(element.id == this.itemSelected.id) {
                this.openSub(element)
              }              
            }                            
          });

          if(this.table.rowSelected!=null) {            
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }         

          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }          
          
          this.table.isBusy = false
        })                         
        .catch(error => {                 
          this.table.isBusy = false  
          this.$awn.alert(ErrorToken.valid(error));
        })  
      },        
      
      // COMPROBANTE DETAIL
      openSub(item) {                
        this.itemSelected = item        
        
        this.tableSub.items = item.details      
        this.loadStyleConfig()
      },    
      hideSub() {
        this.tableSub.items=[]        
        this.itemSelected = null
      },     
      openDetail() {              
        this.tableSub.items = this.itemSelected.details            
        this.tableDetail.items = this.itemSelected.details            
       
        this.modal.detail.title = "Detalle del Comprobante"
        this.modal.detail.active = true
      },        
      getDetailCode(item) {        
        if(item.products_id || item.products_colors_id || item.products_waist_id || item.products_color_waist_id) {
          return this.getProductCode(item)
        }            
        if(item.products_compound_id) {          
          return item.products_compound.code
        }                
      },
      getDetailName(item) {        
        if(item.products_id || item.products_colors_id || item.products_waist_id || item.products_color_waist_id) {
          return this.getProductName(item)
        }               
        if(item.products_compound_id) {          
          return item.products_compound.name
        }                
      },       
      getProductCode(item) {
        var code = ''
        var prod = null

        if(item.products_code) {
          code = item.products_code
        }          

        if(item.products_id) {
          if(code) {
            return code
          } else {                      
            return item.product.code
          }
        }

        if(item.products_colors_id) {
          prod = item.products_color 
          if(code) {
            return code
          } else {
            return prod.code
          }           
        }

        if(item.products_waist_id) {
          prod = item.products_waist 
          if(code) {
            return code
          } else {
            return prod.code
          }                     
        }

        if(item.products_color_waist_id) {
          prod = item.products_color_waist 
          if(code) {
            return code
          } else {
            return prod.code
          }                     
        }
      },      
      getProductName(item) {
        var name = ''
        var prod = null

        if(item.products_name) {
          name = item.products_name
        }          
        
        if(item.products_id) {
          if(name) {
            return name
          } else {
            return item.product.name
          }          
        }

        if(item.products_colors_id) {
          prod = item.products_color 
          if(name) {
            return name + ' (' + prod.color.name + ')'
          } else {
            return prod.name + ' (' + prod.color.name + ')'
          }                    
        }

        if(item.products_waist_id) {
          prod = item.products_waist           
          if(name) {
            return name + ' (' + prod.waist.name + ')'
          } else {
            return prod.name + ' (' + prod.waist.name + ')'
          }
        }

        if(item.products_color_waist_id) {
          prod = item.products_color_waist 
          if(name) {
            return name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
          } else {
            return prod.name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
          }          
        }
      },  
      
      // SET PRICE
      openSetPrice(item) {
        this.itemSelected = item
        this.itemSelectedPrice = item.details        
        
        this.modal.price.title = "Precio Productos"
        this.modal.price.active = true
      },
      saveSetPrice() {
        this.$bvModal.msgBoxConfirm('¿Desea guardar los precios de los productos?', {
          title: 'Guardar Precios',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();
          
            var result = serviceAPI.guardarPreciosRemito({
              id: this.itemSelected.id,
              details: this.itemSelectedPrice
            });

            result.then((response) => {
              this.modal.price.active = false
              loader.hide()
              this.filterPurchaseRemits()
              this.$awn.success("Precios guardado con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },
      getDiscount(item, nivel=1) {
        if(nivel==1) {
          return parseFloat(item.percentage_discount)
        }        
        if(nivel==2) {
          return parseFloat(item.percentage_discount_1)
        }        
        if(nivel==3) {
          return parseFloat(item.percentage_discount_2)
        }                        
      },
      getAdditional(item, nivel=1) {
        if(nivel==1) {
          return parseFloat(item.additional_percentage)
        }        
        if(nivel==2) {
          return parseFloat(item.additional_percentage_1)
        }                
      }, 
      
      // PRINT
      sendPrinter(item) {            
        if(item.deposit_operation) {
          this.crud.print.key=Math.random()
          this.crud.print.id=item.deposit_operation.id
          this.crud.print.reference = item.deposit_operation.reportDefault
        } else {
         this.$awn.alert("No se encontró el movimiento de depósito");
         return false 
        }
      },           
    }    
   
  }
</script>

<style>
  .crud-purchase-remits-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-purchase-remits-code {
    color: gray;
    font-size: 12px;
  }   
  #details_items {
    position: fixed;
    right: 15px;    
  }   
  .table-purchase-sub {
    overflow: auto;
    max-height: 350px;
  }  
  .crud-purchase-remits-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }  
  .crud-purchase-remits-value-table-custom {
    padding-left: 5px;
    white-space: pre-line;
  }    
  .table-full-detail-purchase-remits {
    overflow: auto;
    max-height: 350px;    
  }    
  .crud-purchase-remits-action-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;    
  }
  .crud-purchase-remits-price-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-purchase-remits-price-code {
    color: gray;
  }
  .purchase-remits-price-cost-divider {
    border-left: 1px solid #c7c7c7;
    text-align: end;        
    padding-top: 5px;
  }  
  .crud-purchase-remits-title-table-price {
    color: #2f353a;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }  
  .crud-purchase-remits-value-table-price {
    padding-left: 5px;
    text-align: right
  }    
  .purchase-remits-price-cost-update {
    text-align: end; 
    padding-top: 5px;
  }
  .purchase-remits-list-price-cost {
    overflow: auto;
    max-height: 350px;
  }
  .purchase-remits-price-cost-not-related {
    font-size: 10px;
    color: red;
  }
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>